import React, { useEffect, useState } from 'react';
import {Redirect} from 'react-router-dom';
import {isEmailValid} from '../../Services/Validators';
import logo from '../../Images/logo.png';
import { LoginUser } from '../../Services/UserService';

import {VscError} from 'react-icons/vsc';
import Config from '../../Config';


const Login = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [emailError, setEmailError] = useState<Boolean>(false);

    const [loginError, setLoginError] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");

    let [bRedirect, setbRedirect] = useState<boolean>(false);
    
    function submitForm(e){
        e.preventDefault();
        LoginUser(email, password).then((res:any) => {
            // Erreur de connexion
            if(res[1] !== null){
                console.log(res);
                setLoginError(true);
                switch(res[1]){
                    case 'Invalid credentials':
                        setLoginErrorMessage("Adresse mail ou mot de passe incorrect.");
                        break;
                    case 'Not found':
                        setLoginErrorMessage(
                          'Adresse mail ou mot de passe incorrect.'
                        );
                        break;

                }
            }else{
                // Connexion OK
                setLoginError(false);
                setLoginErrorMessage("");
                const token = res[0];
                if(rememberChecked){
                    // On garde les infos en localstorage
                    localStorage.setItem("auth_token", token);
                }else{
                    // On garde les infos en session
                    sessionStorage.setItem("auth_token", token);
                }
                setbRedirect(true);
            }
        });
    }

    const [rememberChecked, setRememberChecked] = useState<boolean>(false);
    function rememberChanged(e){
        setRememberChecked(e.target.checked);
    }

    if(bRedirect){
        window.location.reload();
    }

    return (
      <div className="flex h-screen">
        <div className="mx-auto mt-10 w-full md:w-full lg:w-1/4 flex flex-col items-center space-y-4">
          <a href="/">
            <img src={logo} className="h-24 w-24" />
          </a>
          <h1 className="text-xl font-semibold mb-4">
            Se connecter à{' '}
            <span className="text-blue-800 text-bold">{Config.sitename}</span>
          </h1>

          <form
            className="w-full flex flex-col bg-white rounded-md shadow p-4 space-y-4"
            onSubmit={submitForm}
          >
            <label className="block w-full">
              <span className="text-gray-700">Adresse mail</span>
              <input
                type="email"
                autoComplete="username"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                required
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                onBlur={() =>
                  email.length > 0
                    ? setEmailError(!isEmailValid(email))
                    : setEmailError(false)
                }
              />
            </label>
            <label className="block w-full">
              <span className="text-gray-700 flex justify-between">
                <span>Mot de passe</span>
                <a
                  href="/resetpassword"
                  className="text-blue-500 text-sm self-end"
                >
                  Mot de passe oublié?
                </a>
              </span>
              <input
                type="password"
                autoComplete="current-password"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-blue-50 focus:ring-0"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div className="space-x-2 flex items-center">
              <input
                type="checkbox"
                className="rounded text-green-600 focus:ring-0"
                id="remember"
                onChange={rememberChanged}
              />
              <label htmlFor="remember">Se souvenir de moi</label>
            </div>
            {loginError && (
              <div className="shadow h-12 rounded flex items-center">
                <div className="rounded h-12 w-12 bg-red-500 mr-2 flex items-center">
                  <VscError className="text-3xl w-full text-white" />
                </div>
                <span>{loginErrorMessage}</span>
              </div>
            )}
            <button className="from-green-500 to-green-600 bg-gradient-to-r py-2 px-4 rounded shadow block my-4 text-white font-bold">
              Se connecter
            </button>
          </form>
          <div className="w-full rounded-md p-4 border space-y-4 items-center text-center">
            Nouveau chez nous ?{' '}
            <a href="/register" className="text-blue-500 text-sm">
              Créer un compte
            </a>
          </div>
        </div>
      </div>
    );
}

export default Login;