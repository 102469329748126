import { useState } from "react"

const CustomImage = (props:any) => {
    const [error, setError] = useState<boolean>(false);

    if(error){
        return <div className="flex bg-blue-300 items-center font-semibold rounded shadow p-4">{props.name}</div>
    }
    return <img src={props.src} className="h-10" onError={() => setError(true)} />
}

export default CustomImage;