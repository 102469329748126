import { RadioGroup } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { ILan, ILanParticipant } from '../../Interfaces/ILan';
import { GetLanParticipantByLanID, GetUserLanDetail, PaiementSuccess, SigninLan } from '../../Services/LanService';
import { PayPalButton } from 'react-paypal-button-v2';

interface IPlan {
    id:string,
  name: string;
  description: string;
  prix: Number;
}

let plans: IPlan[] = [
  {
      id: 'visiteur',
    name: 'Visiteur',
    description:
      'Je viens de manière ponctuelle pour passer un moment avec les autres',
    prix: 20,
  },
  {
      id:'joueur',
    name: 'Joueur',
    description: 'Je viens pour participer à la LAN',
    prix: 0,
  },
];

const Participe = () => {
  let { id } = useParams();

  const [directRedirect, setDirectRedirect] = useState<boolean>(false);
  const [lan, setLan] = useState<ILanParticipant>();

  const [PC, setPC] = useState<string | undefined>('');
  const [enable, setenable] = useState(false);
  const [Commentaire, setCommentaire] = useState<string | undefined>('');
  const [acceptConditions, setacceptConditions] = useState<boolean>(false);
  const [PaypalShow, setPaypalShow] = useState<boolean>(false);

  const [price, setPrice] = useState<number>(0);

  const [paypalSuccess, setPaypalSuccess] = useState<boolean>(false);

  useEffect(() => {
    GetLanParticipantByLanID(id).then((lan: ILanParticipant | null) => {
      if (lan) {
        console.log(lan);
        setLan(lan);
        plans[1].prix = lan.lan.engagement;
        plans[0].prix = lan.lan.engagement_visiteur;
        setSelected(plans[1]);
        if (new Date() > new Date(lan.lan.stop_at) || (lan.participe && lan.paiement)) {
          setDirectRedirect(true);
        }
        if(lan.participe && !lan.paiement){
          setPaypalShow(true);
          GetUserLanDetail(id).then((res:{status:ILanParticipant}) => {
            setPrice((res.status.visiteur ? lan.lan.engagement_visiteur : lan.lan.engagement) as number);
          });
        }
      }
    });
  }, []);

  const onSuccess = async (details: any, data: any) => {
      if(!lan) return;
    const success = await PaiementSuccess(details, data, lan, price as number);
    setPaypalSuccess(success);
  };

  function onSubmit(e){
      e.preventDefault();
      if(!lan || (selected.id=='joueur'&&!PC)) return;
      SigninLan(lan?.lan.id, Commentaire ?? "", PC ?? "", selected.id == 'joueur').then(res => {
          if(res){
            setPaypalShow(true);
          }
      })
  }

  const [selected, setSelected] = useState<IPlan>(plans[0]);

  function selectionChanged(e:IPlan){
    setSelected(e);
    setPrice(e.prix as number);
  }

  if(!lan){
      return (<div>...</div>)
  }

  if(paypalSuccess){
      return(
      <div>
        {directRedirect && <Redirect to="/" />}

        <h1 className="text-xl">
          Inscription à <span className="font-semibold">{lan?.lan.title}</span>
        </h1>

        <div className="m-4 p-2 bg-green-200 text-center rounded">
            Inscription validée avec succès !
        </div>

        <a className="flex justify-center text-blue-800 p-2" href={`/lan/${lan.lan.id}`}>Retour à la lan</a>
      </div>
      )
  }

  if(PaypalShow){
      return (
        <div>
          {directRedirect && <Redirect to="/" />}

          <h1 className="text-xl">
            Inscription à{' '}
            <span className="font-semibold">{lan?.lan.title}</span>
          </h1>

        <p className="p-2 bg-yellow-100 m-2 rounded shadow text-xs sm:text-sm">Votre inscription est en attente de validation car vous n'avez pas encore réglé les frais de participation à hauteur de <b>{price}€</b>. Après réception de votre paiement, votre inscription sera automatiquement validée.</p>

          <PayPalButton
            amount={price.toFixed()}
            onSuccess={(details: any, data: any) =>
              onSuccess(details, data)
            }
            shippingPreference="NO_SHIPPING"
            options={{
              clientId:
                process.env.NODE_ENV === 'development'
                  ? 'AQBDDtX61akO7Y_lOiw8bDz72FIvP82UHsI4-Xu_idY8aXhfhpj2EPxM5E4j9UPDcsvIxsoCJqGqWiMv'
                  : 'AZKXj05sooJHKGQ4MZfgZI9zYvgygIjVrofmm29LtKE13LsHem52T7JhADlM_sjf1PGx3NpoRaqm3lKC',
              currency: 'EUR',
              commit: true,
            }}
          />
        </div>
      );
  }

  return (
    <div>
      {directRedirect && <Redirect to="/" />}

      <h1 className="text-xl">
        Inscription à <span className="font-semibold">{lan?.lan.title}</span>
      </h1>

      <h2>
        du {new Date(lan?.lan.start_at).toLocaleDateString()} au{' '}
        {new Date(lan.lan.end_at).toLocaleDateString()}
      </h2>

      <RadioGroup value={selected} onChange={selectionChanged}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-2">
          {plans.map((plan) => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ active, checked }) =>
                `${
                  active
                    ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                    : ''
                }
                  ${
                    checked
                      ? 'bg-blue-900 bg-opacity-75 text-white'
                      : 'bg-white'
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium  ${
                            checked ? 'text-white' : 'text-gray-900'
                          }`}
                        >
                          {plan.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? 'text-sky-100' : 'text-gray-500'
                          }`}
                        >
                          <span>{plan.description}</span>{' '}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-white">
                        <CheckIcon className="w-6 h-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className="mt-5">
        <div className="flex justify-end items-end content-center">
          Montant des frais d'engagement{' '}
          <span className="font-bold text-5xl ml-5=">{selected.prix}€</span>
        </div>
      </div>
      <div className="mt-5 p-4 bg-blue-100 rounded text-blue-900">
        <h1 className="font-semibold text-sm sm:text-base">Information</h1>
        <p className="sm:text-base text-xs">
          Les frais d'engagement permette d'accèder au buffet, aux petits fours et au champagne !<br></br>
          Avant de poursuivre, nous tenons à vous informer que des frais
          d'engagement sont en vigueur. Ces frais d'engagement à hauteur de{' '}
          <span className="font-bold">{selected.prix}€</span> doivent être
          réglés pour valider l'inscription. Une participation complémentaire
          sera demandée à l'issu de l'évenement, afin de combler les dépenses
          durant ce dernier.
        </p>
      </div>
      <form action="#" onSubmit={onSubmit}>
        {selected.id == 'joueur' && (
          <div className="mt-5">
            <h2 className="font-semibold">Comment venez-vous ?</h2>
            <div className="flex items-center space-x-3">
              <input
                id="equiped"
                className="rounded text-blue-600 focus:ring-0"
                type="radio"
                name="pc_choice"
                required={selected.id == 'joueur'}
                value="owned"
                onChange={(event) => setPC(event.target.value)}
              />
              <label htmlFor="equiped" className="text-sm">Je viens avec mon PC</label>
            </div>
            <div className="flex items-center space-x-3">
              <input
                id="equiped"
                className="rounded text-blue-600 focus:ring-0"
                type="radio"
                name="pc_choice"
                required={selected.id == 'joueur'}
                value="lend"
                onChange={(event) => setPC(event.target.value)}
              />
              <label htmlFor="equiped" className="text-sm">
                Je viens, mais il faut me prêter un PC
              </label>
            </div>
          </div>
        )}
        <div className="mt-5">
          <h2 className="font-semibold">
            Commentaire :{' '}
            <span className="text-gray-600 italic text-xs">(facultatif)</span>
          </h2>
          <textarea
            rows={4}
            placeholder="Je viens avec un switch supplémentaire... Je viens avec un PC portable en rab..."
            className="rounded focus:ring-0 w-full"
            onChange={e => setCommentaire(e.target.value)}
          ></textarea>
        </div>
        <div className="mt-5 flex items-center space-x-1">
          <input
            type="checkbox"
            id="cgu"
            name="cgu"
            className="rounded text-blue-600 focus:ring-0"
            required
            onChange={(e) => setacceptConditions(e.target.checked)}
          />
          <label htmlFor="cgu">
            J'accepte les{' '}
            <a
              target="_blank"
              className="text-blue-500"
              href="http://localhost:3001/static/media/cgu.ddd6a41c.pdf"
            >
              conditions générales
            </a>
          </label>
        </div>
        <div className="mt-5">
          <button className="bg-blue-500 w-full p-4 rounded text-white font-semibold">
            Valider et passer au paiement
          </button>
        </div>
      </form>
    </div>
  );
};

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Participe;
