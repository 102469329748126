import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GetStoredToken, GetUserFromToken } from './Services/UserService';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import Register from './Components/Register/Register';
import AuthHome from './Components/AuthHome/AuthHome';
import { IAuthUser } from './Interfaces/IAuthUser';
import MyGames from './Components/MyGames/MyGames';
import AuthTemplate from './Components/Partials/AuthTemplate';
import LansList from './Components/LansList/LansList';
import ViewLan from './Components/ViewLan/ViewLan';
import Participe from './Components/Participe/Participe';
import UserProfile from './Components/UserProfile/UserProfile';
import Game from './Components/Game/Game';

const GetUser = async() => {
  const token = GetStoredToken();
  return await GetUserFromToken(token[1]);
}
const AuthContext = React.createContext<IAuthUser|null>(null);
const user = GetUser();

user.then((u:any) => {
  const authRouter = (
    <AuthContext.Provider value={u}>
      <AuthTemplate>
        <Router>
          <Switch>
            <Route exact path="/">
              <AuthHome />
            </Route>
            <Route exact path="/mygames">
              <MyGames />
            </Route>
            <Route exact path="/lans">
              <LansList />
            </Route>
            <Route path="/lan/:id" exact>
              <ViewLan />
            </Route>
            <Route path="/participe/:id" exact>
              <Participe />
            </Route>
            <Route path="/profil/:id" exact>
              <UserProfile />
            </Route>
            <Route path="/game/:id" exact>
              <Game />
              </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </AuthTemplate>
    </AuthContext.Provider>
  );
  
  const defaultRouter = <Router>
                  <Switch>
                      <Route exact path="/">
                          <Home />
                      </Route>
                      <Route exact path="/login">
                          <Login />
                      </Route>
                      <Route exact path="/resetpassword">
                          <ResetPassword />
                      </Route>
                      <Route exact path="/register">
                          <Register />
                      </Route>
                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                  </Switch>
              </Router>
  ReactDOM.render(
    <React.StrictMode>
      {u ? authRouter : defaultRouter}
    </React.StrictMode>,
    document.getElementById('root')
  );
})

export {AuthContext, user}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
