import { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { IAuthGame } from '../../Interfaces/IAuthGame';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { GetUserById, GetUserGamesByUserID } from '../../Services/UserService';
import CustomImage from '../Partials/CustomImage';

const UserProfile = () => {
  let { id } = useParams();

  const [user, setUser] = useState<IAuthUser>();
  const [uGames, setUGames] = useState<IAuthGame[]>([]);

  useEffect(() => {
    GetUserById(id).then((u: IAuthUser | null) => {
      if (u) {
        setUser(u);
        GetUserGamesByUserID(u.id).then((games: IAuthGame[] | null) => {
          if (games) {
            setUGames(games);
          }
        }).catch(console.error);
      }
    });
  }, []);

  if (user) {
    return (
      <div>
        <div className="flex space-x-5">
          <img
            className="rounded shadow"
            src={user.steam_profil?.avatarmedium}
          />
          <div>
            <h1 className="text-2xl font-semibold">
              {user.prenom} {user.nom.substring(0, 1)}.
            </h1>
            <h2>{user.username}</h2>
          </div>
        </div>
        <span className="mt-5 flex">Ce joueur posséde {uGames.length} jeux</span>
        <div className="sm:hidden flex flex-col space-y-2 mt-5">
          {uGames.map((g: any) => (
            <div key={g.app_id} className="p-4 shadow rounded flex flex-row">
             <a href={`/game/${g.app_id}`}><img src={g.icon_url} className="mr-2 h-10 w-10" /></a>
              <div className="flex flex-col">
                <span>{g.name}</span>
                <span className="text-xs">
                  {Math.floor(g.playtime_forever / 60)}h
                  {g.playtime_forever % 60}
                  min
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden flex-col sm:flex">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Jeu
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Logo
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Temps de jeu
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {uGames.length == 0 && (
                      <tr>
                        <td colSpan={3} className="px-6 py-4 whitespace-nowrap">
                          Il n'y a rien à afficher... 😥
                        </td>
                      </tr>
                    )}
                    {uGames.map((g: IAuthGame) => {
                      return (
                        <tr key={g.app_id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <a href={`/game/${g.app_id}`}><img
                                  className="h-10 w-10 rounded-full"
                                  src={g.icon_url}
                                  alt=""
                                /></a>
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {g.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <CustomImage src={g.logo_url} name={g.name} />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {Math.floor(g.playtime_forever / 60)}h
                              {g.playtime_forever % 60}min
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default UserProfile;
