import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'; 
import {isEmailValid} from '../../Services/Validators';
import logo from '../../Images/logo.png';
import { SendForgetEmail } from '../../Services/UserService';


const recaptchaRef:any = React.createRef();

const ResetPassword = () => {


    function captchaChanged(value){
        if(value){
            setCaptcha(true);
        }
    }

    function submitForm(e){
        e.preventDefault();
        SendForgetEmail(email).then((res:any) => {
            console.log(res);
            // L'adresse mail n'est pas valide
            if(res[1] !== null){
                setEmailError("L'adresse mail spécifiée n'existe pas.");
                recaptchaRef.current.reset();
            }else{
                // Email envoyé avec succès
                setEmailError("");
                setEmailSent(true);
            }
        })
    }

    const [captcha, setCaptcha] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");
    return (
      <div className="flex h-screen">
        <div className="mx-auto mt-10 w-full md:w-full lg:w-1/4 flex flex-col items-center space-y-4">
          <a href="/">
            <img src={logo} className="h-24 w-24" />
          </a>
          <h1 className="text-xl font-semibold mb-4">
            Réinitialiser votre mot de passe
          </h1>

          {!emailSent ? (
            <form
              className="w-full flex flex-col bg-white rounded-md shadow p-4 space-y-4"
              onSubmit={submitForm}
            >
              <label className="block w-full">
                <span className="text-gray-700 text-sm font-semibold">
                  Veuillez indiquer l'adresse mail que vous avez utilisé lors de
                  votre inscription, et vous nous enverrons un mail de
                  réinitialisation.
                </span>
                <input
                  type="email"
                  className={`mt-1 block w-full rounded-md bg-gray-100 ${
                    emailError.length > 0
                      ? 'border-red-600'
                      : 'border-transparent'
                  } focus:border-gray-500 focus:bg-blue-50 focus:ring-0`}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              {emailError.length > 0 && (
                <div className="p-4 bg-red-300 rounded">{emailError}</div>
              )}
              <span className="font-bold">Vérifier votre compte</span>
              <div className="flex p-10 bg-gray-200 justify-center items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcJymscAAAAAAtmX1xjhRzNmKdUM84A3ZL-ysXr"
                  onChange={captchaChanged}
                />
              </div>
              <button
                type="submit"
                disabled={!isEmailValid(email) || !captcha}
                className="py-2 px-4 rounded shadow block my-4 text-white disabled:bg-green-50 bg-green-500 font-bold"
              >
                Envoyer le mail de réinitialisation
              </button>
            </form>
          ) : (
            <div className="w-full flex flex-col bg-white rounded-md shadow p-4 space-y-4">
              <h1 className="text-center font-semibold text-xl">Mail envoyé</h1>
              <p>
                Un email contenant un lien de réinitialisation de votre mot de
                passe vient de vous être envoyé. Pensez à vérifier vos spam.
              </p>
              <a href="/" className="text-center text-blue-500 text-sm">
                Retour à l'accueil
              </a>
            </div>
          )}
        </div>
      </div>
    );
}

export default ResetPassword;