import { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { IAuthUser } from '../../Interfaces/IAuthUser';
import { GetUsersByGameAppId, IGameUsers } from '../../Services/GameService';

const Game = () => {
  let { id } = useParams();

    const [infos, setInfos] = useState<IGameUsers>();

    const [error,setError] = useState<boolean>(false);

    useEffect(() => {
        GetUsersByGameAppId(id).then((users:IGameUsers|undefined) => {
            if(!users){
                setError(true);
            }
            setInfos(users);
        });
    }, []);

    if(error){
        return <Redirect to="/" />
    }

    if(!infos){
        return <div>Chargement...</div>
    }

  return (
    <div>
      <h1 className="sm:text-2xl text-xl font-semibold">{infos?.game.name}</h1>
      <img src={infos?.game.logo_url} />

      <h2 className="mt-5 font-semibold text-lg">Joueurs</h2>
      <span>
        Il y {infos?.users.length} joueur{infos?.users.length > 1 ? 's ' : ' '}
        qui {infos?.users.length > 1 ? 'ont' : 'a'} ce jeu
      </span>

      <div className="grid sm:grid-cols-6 grid-cols-1 gap-4">
          {infos.users.map((u:IAuthUser) => 
            <div className="p-4 bg-gray-100 rounded-md flex relative" key={u.id}>
            <a href={`/profil/${u.id}`}>
                <img
                src={u.steam_profil?.avatarmedium}
                className="rounded shadow h-16 w-16"
                />
            </a>
            <div className="flex flex-col ml-4 leading-10">
                <span className="text-xs lg:text-xl sm:text-base md:text-xs font-semibold">
                {u.username.substring(0,15)}
                </span>
                <span className="text-sm">
                {u.prenom} {u.nom.substring(0, 1)}.
                </span>
            </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default Game;
