export default {
  sitename: 'La Francis',
  subname: 'Lan entre amis',
  /*baseURL:
    process.env.NODE_ENV == 'development'
      ? 'http://127.0.0.1:5000'
      : 'http://api.lafrancis.fr',*/
  baseURL:
    process.env.NODE_ENV == 'development'
      ? 'http://api.lafrancis.fr'
      : 'http://api.lafrancis.fr',
};