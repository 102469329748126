import Config from "../Config";
import {IAuthUser} from '../Interfaces/IAuthUser';
import qs from 'querystring';
import { IAuthGame } from "../Interfaces/IAuthGame";

const LoginUser = async (email:string, password:string) => {
    const request = await fetch(`${Config.baseURL}/auth/login`, {
      method: 'POST',
      body: qs.stringify({
        email,
        password,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await request.json();
    return result.error ? [null, result.error] : [result.token, null];
}

const SendForgetEmail = async (email:string) => {
  const request = await fetch(`${Config.baseURL}/auth/forget`, {
    method: 'POST',
    body: qs.stringify({
      email
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const result = await request.json();
  return result.error ? [null, result.error]:[true, null];
}

const GetUserFromToken = async (token:string|null):Promise<IAuthUser|null> => {
  if(!token) return null;
  const request = await fetch(`${Config.baseURL}/auth/user`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
  const result = await request.json();
  return result;
}

const DisconnectUser = () => {
  localStorage.removeItem('auth_token');
  sessionStorage.removeItem ('auth_token');
  window.location.reload();
}

const GetUserGames = async() => {
  const token = GetStoredToken()[1];
  if(!token) return;
  const user = await GetUserFromToken(token);
  const request = await fetch(`${Config.baseURL}/steam/games/${user?.id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
  const result = await request.json();
  return result;
}

const GetStoredToken = () => {
  return localStorage.getItem("auth_token") ? ["localstorage", localStorage.getItem("auth_token")] : ["sessionstorage",sessionStorage.getItem("auth_token")];
}

const RegisterUser = async(nom:string, prenom:string, email:string, link:string, pseudo:string) => {
  const query = qs.stringify({
    nom,
    prenom,
    email,
    link,
    pseudo
  });
  const req = await fetch(`${Config.baseURL}/auth/create`, {
    method: 'post',
    body: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const res = await req.json();
  if (!res.error) {
    // Tout s'est bien passé
    return [true,null];
  }
  return [false, res.error];
}

const GetUserGamesByUserID = async (id: number): Promise<IAuthGame[] | null> => {
  const token = GetStoredToken()[1];
  if (!token) return null;
  const req = await fetch(`${Config.baseURL}/steam/games/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await req.json();
};

const GetUserById = async (id: number): Promise<IAuthUser | null> => {
   const token = GetStoredToken()[1];
   if (!token) return null;
  const req = await fetch(`${Config.baseURL}/auth/user/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (req.status === 200) {
    return await req.json();
  }
  return null;
};

export {
  LoginUser,
  SendForgetEmail,
  GetUserFromToken,
  DisconnectUser,
  GetStoredToken,
  GetUserGames,
  RegisterUser,
  GetUserGamesByUserID,
  GetUserById
};